import React, { Suspense, lazy, useState } from 'react';
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import FeatureGrid from "@components/feature-grid";
import PartnerArea from "@containers/partner/layout-01";
import IntroArea from "@components/introarea/layout-1";
import Slider3D from "@containers/global/slider-3d";
import WebsiteLocIntegrations from "@containers/website-localization/integrations";
import IntroAreaReverse from "@components/introareareverse/layout-1";
import CaseStudy from "@containers/global/case-study/layout-01";
import FaqArea from "@containers/faq/layout-03";
import TechnologySection from "@containers/technology/layout-02"
import FlipBoxSection from "@components/BoxSection/layout-one/layout-7";
import BoxSectionTwo from "@components/BoxSection/layout-one/layout-2";
import CtaArea from "@containers/cta/layout-02";
import { caseStudiesData } from "@constants";
import CtaAreaToday from "@containers/cta/layout-08";
import StickyMenu from "@containers/sticky-menu";
import QuoteForm from "@containers/translation-quote-form";
import UseScroll from "@containers/scroll";
import BoxSection from "@components/BoxSection/layout-three/layout-3";
import BoxSectionOne from "@components/BoxSection/layout-one/layout-3";
const Footer = lazy(() => import("@layout/footer/layout-01"))
const WebsiteLocalizationPage = ({ location, data }) => {
  const [showRestComponents, setShowRestComponents] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);
  UseScroll((scrollTop) => {
    if (scrollTop > 30) {
      setShowRestComponents(true);
    } else {
      setShowRestComponents(false);
    }
  }, setIsMobile);
  return (
    <Layout location={location}>
      <Seo
        title="Professional Website Localization Services | Andovar"
        description="Looking for expert website localization services? Andovar offers customized, professional solutions to adapt your content for global audiences. Contact us today"
      />
      <Header
        data={{
          ...globalContent["header"],
          ...globalContent["menu"],
          ...data.site.siteMetadata,
          isMobile: isMobile
        }}
      />
      {
        !isMobile && <StickyMenu data={content["stycky-menu-data"]} setShowRestComponents={setShowRestComponents} showRestComponents={showRestComponents} />
      }
      <main className="site-wrapper-reveal">
        <PageHeader data={content["website-localization-header-data"]} />
        <QuoteForm />
        {
          (!isMobile || (isMobile && showRestComponents)) && <>
            <PartnerArea data={content["website-localization-logo"]} />
            <FeatureGrid data={content["website-localization-features"]} />
            <IntroArea layout={4} data={content["website-localization-intro"]} />
          </>
        }
        {
          showRestComponents && <>
            <BoxSection layout={8} data={content["web-localization-about"]} />
            <IntroAreaReverse layout={6}
              data={content["website-localizationl-why-us"]}
            />
            <CtaAreaToday data={content["website-localization-cta-today"]} />
            <TechnologySection layout={1} data={content["technology-section-data"]} />
            <WebsiteLocIntegrations
              data={content["website-localization-integration"]}
            />
            <CtaArea data={content["cta-data"]} />
            <IntroArea
              layout={4}
              data={content["website-localization-multi-seo"]}
            />
            <FlipBoxSection data={content["web-localization-process"]} />
            <BoxSectionOne layout={8} data={content["web-localization-benefits"]} />
            <Slider3D data={content["website-localization-applications"]} />
            <BoxSectionTwo layout={12} data={content["web-localization-quality"]} />
            <CaseStudy data={caseStudiesData} />
            <FaqArea space={3} data={content["website-localization-faq-body"]} />
            <BoxSectionOne layout={9} data={content["website-localization-cta-data"]} />
          </>
        }
      </main>
      {
        showRestComponents && <Suspense fallback={<div></div>}>
          <Footer data={{ ...data.site.siteMetadata }} />
        </Suspense>
      }
    </Layout>
  );
};

export const query = graphql`
  query websiteLocalizationPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(title: { eq: "website-localization" }, pageType: { eq: "innerpage" }) {
      content {
        ...PageContent
      }
    }
  }
`;

WebsiteLocalizationPage.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default WebsiteLocalizationPage;
